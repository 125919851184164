<template>
  <div id="container" class="pointer-events-auto">
    <svg height="240" width="240">
      <g class="logo">
        <g class="left-pole">
          <line x1="0" y1="0" x2="0" y2="200" style="stroke:white;stroke-width:12" />
        </g>
        <g class="right-pole">
          <line x1="0" y1="0" x2="0" y2="200" style="stroke:white;stroke-width:12" />
        </g>
      </g>
    </svg>
    <h1>Velkommen til Telt!</h1>
    <div style="width: 400px; margin: auto; text-align: left">
      Her finner du <strong>12 234</strong> magiske steder hvor du kan overnatte i telt eller
      hengekøye, helt gratis!<br /><br />
      Finn et sted på kartet, søk etter et sted, eller se etter steder i nærheten.<br /><br />
      Vet du om et magisk sted? Del det gjerne med oss!<br /><br />
      <strong>God tur! Hilsen Bjørnar</strong><br /><br />
    </div>
    <button
      class="start-button"
      @click="$router.push({ name: 'home', params: { transition: 'slide-bottom' } })"
    >
      Start
    </button>
    <br /><br />
  </div>
</template>

<script>
export default {}
</script>

<style>
#container {
  width: 400px;
  margin: 80px auto;
  padding: 30px;
  border-radius: 20px;
  background-color: #5da9a3;
}

.start-button {
  color: white;
  padding: 10px 30px;
  font-size: 20px;
  border: none;
  background-color: #2c3e50;
  border-radius: 10px;
  text-decoration: none;
}

.logo {
  opacity: 0;
  /* animation: slide-in 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1400ms forwards; */
  animation: slide-in2 1400ms 1400ms forwards;
}

@keyframes slide-in {
  0% {
    transform: translate(120px, 240px);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(120px, 30px);
  }
}

@keyframes slide-in2 {
  0% {
    transform: translate(120px, 240px);
  }

  24% {
    transform: translate(120px, 14px);
  }

  34% {
    opacity: 1;
    transform: translate(120px, 36px);
  }

  48% {
    opacity: 1;
    transform: translate(120px, 16px);
  }

  75% {
    opacity: 1;
    transform: translate(120px, 33px);
  }

  100% {
    opacity: 1;
    transform: translate(120px, 30px);
  }
}

.left-pole {
  animation: left-pole-animation 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1150ms forwards;
}

@keyframes left-pole-animation {
  100% {
    transform: translate(20px, 0) rotate(30deg);
  }
}

.right-pole {
  animation: right-pole-animation 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1150ms forwards;
}

@keyframes right-pole-animation {
  100% {
    transform: translate(-20px, 0) rotate(-30deg);
  }
}
</style>
